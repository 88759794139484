@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.grid-item {
  position: relative;
  width: 31.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 2px;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  transition: all ease 0.1s;

  @include screen('mobile') {
    width: 48%;
    padding: 12px;
  }

  &.animation {
    border: 1px solid transparent;
    background-position: -500px 0;
    background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 20%,
      rgba(255, 255, 255, 0.2) 40%,
      rgba(255, 255, 255, 0.1) 100%
    );
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    animation: skeletonShine 0.5s linear 0s infinite normal forwards;

    .photo {
      opacity: 0;
    }
    .info {
      opacity: 0;
    }
    .icon {
      opacity: 0;
    }
    .shares-toggler {
      opacity: 0;
    }
  }

  .photo {
    flex-shrink: 0;
    width: 154px;
    height: 154px;
    border-radius: 2px;
    overflow: hidden;
    border: solid 1px rgba(255, 255, 255, 0.6);
    margin-bottom: 16px;
    transition: all ease 0.1s;

    @include screen('mobile') {
      position: relative;
      width: 100%;
      height: initial;
      &:before {
        content: '';
        display: block;
        padding-top: 100%; /* initial ratio of 1:1*/
      }
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include screen('mobile') {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  .info {
    width: 100%;
    flex-grow: 1;
    transition: all ease 0.1s;

    .top {
      font-size: 20px;
      line-height: 20px;
      font-family: 'Yonit-Bold';
      color: #fff;

      @include screen('mobile') {
        font-size: 16px;
        line-height: 16px;
        text-align: center;
      }
    }

    .bottom {
      font-size: 18px;
      line-height: 18px;
      font-family: 'Yonit-Regular';
      color: #fff;
      padding-bottom: 16px;

      @include screen('mobile') {
        font-size: 18px;
        padding-bottom: 12px;
      }
    }

    .description {
      font-family: 'Yonit-Regular';
      font-size: 14px;
      color: #fff;
      line-height: 16px;
      padding-bottom: 16px;

      @include screen('mobile') {
        padding-bottom: 12px;
      }
    }
  }

  .shares-and-role-image {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .shares {
    position: relative;
  }

  .shares-list {
    display: flex;
    flex-flow: column nowrap;
    gap: 8px;
    background-color: #333e50;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    overflow: hidden;
    height: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    transition: 0.2s height ease-in-out;

    &.visible {
      height: 114px;
      padding-bottom: 8px;
      @include screen('mobile') {
        height: 122px;
      }
    }
  }

  .shares-toggler {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    background-color: #475161;
    opacity: 1;
    transition: 0.2s opacity ease-in;

    @include screen('mobile') {
      width: 36px;
      height: 36px;
    }

    &:hover {
      opacity: 0.8;
    }

    img {
      display: block;
    }
  }

  .share-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.1);
    transition: 0.2s ease-in background-color;

    @include screen('mobile') {
      width: 36px;
      height: 36px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    img {
      display: block;
      width: 15px;
      @include screen('mobile') {
        width: 18px;
      }
    }
  }

  .icon {
    width: 24px;
    transition: all ease 0.1s;

    @include screen('mobile') {
      margin-top: 7px;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}

@keyframes skeletonShine {
  to {
    background-position: 0 0;
  }
}
