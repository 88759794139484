@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

@font-face {
  font-family: 'Yonit-Bold';
  src: url('./fonts/Yonit-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yonit-Light';
  src: url('./fonts/Yonit-Light.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yonit-Medium';
  src: url('./fonts/Yonit-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Yonit-Regular';
  src: url('./fonts/Yonit-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
