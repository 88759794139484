@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.main-page {
  text-align: center;
}

.page-wrapper {
  width: 100%;
  max-width: 634px;
  margin: 0 auto;
  overflow-y: auto;

  @include screen('mobile') {
    width: 100%;
    overflow-y: auto;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  padding-left: 18px;
  border-top: 6px solid #2b61b1;

  @include screen('mobile') {
    align-items: center;
    padding-left: 15px;
  }

  .mako-logo {
    width: 462px;
    cursor: pointer;
    @include screen('mobile') {
      width: 296px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
  .candles-logo {
    width: 86px;
    margin-top: 11px;
    @include screen('mobile') {
      width: 63px;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}

.search-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 51px 17px 32px;

  @include screen('mobile') {
    padding: 52px 12px 32px;
  }

  .search-wrapper {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
    height: 51px;
    margin-left: 15px;
    background-color: white;
    border-radius: 2px;
    overflow: hidden;
  }

  .input-wrapper {
    position: relative;
    flex-grow: 1;
    input {
      display: block;
      width: 100%;
      height: 51px;
      line-height: 40px;
      padding: 0 14px 0 31px;
      outline: none;
      border: none;
      font-size: 18px;
      font-family: 'Yonit-Regular';
      color: #041229;
      border: solid 1px rgba(0, 0, 0, 0.3);
      &::placeholder {
        color: #8791ab;
      }
    }

    input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    /* clears the ‘X’ from Chrome */
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    .search-icon {
      position: absolute;
      width: 19px;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      img {
        display: block;
        width: 100%;
      }
    }

    .clear-btn {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 2px;
        top: 50%;
        left: 50%;
        background-color: #8791ab;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .search-btn {
    position: relative;
    width: 50px;
    height: 51px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: 'Yonit-Bold';
    color: #ae0000;
    background-color: rgba(219, 0, 0, 1);
    cursor: pointer;
    &.disabled {
      cursor: inherit;
      pointer-events: none;
    }
  }

  .advanced-search-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 51px;
    border: solid 1px transparent;
    background-color: rgba(71, 81, 97, 1);
    border-radius: 2px;
    font-size: 16px;
    color: #fff;
    font-family: 'Open Sans';
    font-weight: 400;
    cursor: pointer;

    @include screen('mobile') {
      flex-shrink: 0;
      width: 48px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 6px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: rgba(222, 0, 0, 1);
      opacity: 0;
    }

    &.active {
      border: solid 1px rgba(222, 0, 0, 1);
      &:after {
        opacity: 1;
      }
    }
  }
}

.sorting {
  position: relative;
  padding: 0 17px 16px;

  @include screen('mobile') {
    padding: 0 13px 12px;
  }

  .sort-btn {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    padding: 12px 20px;
    border: 1px solid #fff;
    background-color: transparent;
    padding: 9px 13px 9px 13px;
    transition: 0.3s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
      border: 1px solid #fff;
    }

    &.active {
      color: rgba(38, 62, 107, 1);
      background-color: #fff;
      border: 1px solid #fff;
    }

    &.AZSorting {
      border-right: 0;
      border-radius: 2px 0px 0px 2px;
    }
    &.reverseSorting {
      border-left: 0;
      border-radius: 0px 2px 2px 0px;
    }
  }
}

.items-grid {
  position: relative;
  width: 100%;
  padding: 0 16px 16px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-flow: row wrap;
  gap: 16px;

  @include screen('mobile') {
    justify-content: space-between;
    gap: 12px;
    padding: 0 12px 12px;
  }

  &:after {
    position: absolute;
    content: '';
    left: 16px;
    right: 16px;
    height: 1px;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.6);
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
  padding-bottom: 18px;

  .prev-btn,
  .next-btn {
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    font-family: 'Yonit-Bold';
    cursor: pointer;

    &.disabled {
      display: none;
    }
  }

  .prev-btn {
    position: relative;
  }

  .divider {
    width: 1px;
    height: 27px;
    margin: 0 20px;
    background-color: #d9d9d9;
    &.disabled {
      display: none;
    }
  }
}

.no-search-results {
  padding: 32px 0;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  font-family: 'Yonit-Bold';
  text-align: center;
}
