@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100;

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(34, 35, 36, 0.9);
  }

  .wrapper {
    position: fixed;
    width: 505px;
    height: 508px;
    left: 50%;
    top: 80px;
    transform: translate(-50%, 0%);
    padding: 48px 63px 20px;
    background: rgb(2, 6, 24);
    background: linear-gradient(180deg, rgba(2, 6, 24, 1) 0%, rgba(3, 19, 43, 1) 100%);
    border: 1px solid rgba(161, 170, 195, 1);
    @include screen('mobile') {
      width: 100%;
      height: 100%;
      top: 0;
      max-height: 581px;
      padding: 23px 12px 20px;
      border: 1px solid transparent;
    }
  }

  .close-btn {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 24px;
    right: 24px;
    cursor: pointer;
    z-index: 10;
    @include screen('mobile') {
      top: 26px;
      right: 12px;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 18px;
      background-color: rgba(255, 255, 255, 0.6);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .title {
    position: relative;
    padding-bottom: 21px;
    color: #fff;
    text-align: center;
    font-size: 22px;
    line-height: 30px;
    font-family: 'Yonit-Bold';
    @include screen('mobile') {
      padding-bottom: 24px;
      padding-left: 8px;
    }

    .clear-btn {
      position: absolute;
      font-size: 16px;
      opacity: 0.6;
      left: 0;
      top: 0;
      font-family: 'Open Sans';
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;

      &.active {
        opacity: 1;
      }
    }
  }

  .fields {
    width: 100%;

    .field-wrapper {
      margin-bottom: 21px;
      @include screen('mobile') {
        margin-bottom: 35px;
      }
      .field-title {
        padding-bottom: 4px;
        font-size: 18px;
        line-height: 33px;
        color: #fff;
        font-family: 'Yonit-Bold';
        @include screen('mobile') {
          padding-bottom: 2px;
        }
      }

      .role-btns {
        display: flex;
        justify-content: space-between;
        .role-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 62.6px;
          height: 34px;
          border-radius: 2px;
          color: rgba(3, 19, 41, 1);
          font-family: 'Open Sans';
          font-weight: 400;
          font-size: 16px;
          border: 1px solid rgba(161, 170, 195, 1);
          background-color: #fff;
          cursor: pointer;
          @include screen('mobile') {
            width: 18%;
          }
          &.active {
            color: #fff;
            border: 1px solid rgba(222, 0, 0, 1);
            background-color: rgba(222, 0, 0, 1);
          }
        }
      }
    }
  }

  .apply-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 27px;
    @include screen('mobile') {
      padding-top: 34px;
    }
    .btn-apply {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 165px;
      height: 48px;
      color: #fff;
      font-family: 'Open Sans';
      font-size: 16px;
      font-weight: 700;
      border-radius: 2px;
      background-color: rgba(222, 0, 0, 1);
      cursor: pointer;
      @include screen('mobile') {
        width: 100%;
      }
    }
  }
}
